import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { TemplateProps } from '../utils/types'
import { Box, ThemeUIStyleObject } from 'theme-ui'

export const query = graphql`
  query CoverageCheckerPage($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      ...PageData
    }
  }
`

const iframeWrapperSx: ThemeUIStyleObject = {
  width: '100%',
  height: '93.75rem',
}

const iframeSx: ThemeUIStyleObject = {
  width: '100%',
  height: '100%',
}

const CoverageChecker: React.FC<TemplateProps> = ({
  data: { page },
  pageContext: {
    navigation,
    footerNavigation,
    helpNavigation,
    ourStoryNavigation,
  },
}) => {
  return (
    <Layout
      page={page}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      <Box sx={iframeWrapperSx}>
        <iframe
          sx={iframeSx}
          src="https://coverage.ee.co.uk/coverage/transatel"
          frameBorder="0"
        />
      </Box>
    </Layout>
  )
}

export default CoverageChecker
